import { defu } from 'defu';
import type { UseFetchOptions } from '#app';

export function useCustomFetch<T> (url: string | (() => string), options: UseFetchOptions<T> = {}) {
  const { $api } = useNuxtApp();

  const defaults: UseFetchOptions<T> = {
    key: `${url}`,
    $fetch: $api,
    onResponse (_ctx) {},

    onResponseError (_ctx) {},

    onRequest (_ctx) {}
  };

  // for nice deep defaults, please use unjs/defu
  const params = defu(options, defaults);

  return useFetch(url, params);
}

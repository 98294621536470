<template>
  <div
    :class="{'app__search--icon': searchIcon}"
  >
    <input
      v-model="searchStr"
      :placeholder="placeholder"

      @input="onInput"
    >
    <button v-if="searchIcon">
      <img
        v-if="searchIconBlue"
        src="/img/icons/icon-search-blue.svg"
        width="17"
        height="17"
        alt="search icon"
      >
      <img
        v-else
        src="/img/icons/icon-search.svg"
        width="17"
        height="17"
        alt="search icon"
      >
    </button>
  </div>
</template>

<script setup lang="ts" generic="T">
import { useSearch } from '~/utils/useSearch';
import type { AppSearchEmitType } from '~/types/commons';

const props = defineProps<{
	searchList: T[];
	placeholder: string;
  searchIcon?: boolean;
  searchIconBlue?: boolean
}>();

const emits = defineEmits<{
	onInput: [res: AppSearchEmitType<T>]
}>();

const { searchString } = useSearch();
const searchStr = ref<string>('');

const onInput = () => {
  const list = [...props.searchList];
  const res = searchString<T>(searchStr.value, list);

  emits('onInput', {
    isInputClear: searchStr.value.length === 0,
    searchList: res
  });
};

</script>

<style scoped lang="scss">
.app__search {
  &--icon {
    position: relative;
    input {
      padding-right: 25px;
    }
    button {
      position: absolute;
      top: 50%;
      right: 5px;
      transform: translateY(-50%);
      cursor: pointer;
      z-index: 1;
    }
  }
}
</style>
